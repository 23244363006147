import React, { Component } from 'react';

class Portfolio extends Component {
  constructor(props) {
    super(props);
    // Binding this to the method
    this.handleProjectClick = this.handleProjectClick.bind(this);
  }

  handleProjectClick(event) {
    // Prevent the default anchor behavior
    event.preventDefault();
    const url = event.currentTarget.getAttribute('data-url');
    // Navigate to the URL
    window.location.href = url;
  }

  render() {
    let projects;
    if (this.props.data) {
      projects = this.props.data.projects.map((project) => {
        var projectImage = 'images/portfolio/' + project.image;
        return (
          <div key={project.title} className="columns portfolio-item">
            <div className="item-wrap">
              <a href="#" title={project.title} data-url={project.url} onClick={this.handleProjectClick}>
                <img alt={project.title} src={projectImage} />
                <div className="overlay">
                  <div className="portfolio-item-meta">
                    <h5>{project.title}</h5>
                    <p>{project.category}</p>
                  </div>
                </div>
                <div className="link-icon"><i className="fa fa-link"></i></div>
              </a>
            </div>
          </div>
        );
      });
    }

    return (
      <section id="portfolio">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>Projects</h1>
            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
              {projects}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
