import React, { Component } from 'react';

class Blogs extends Component {
  constructor(props) {
    super(props);
    // Binding this to the method
    this.handleBlogClick = this.handleBlogClick.bind(this);
  }

  handleBlogClick(event) {
    // Prevent the default anchor behavior
    event.preventDefault();
    const url = event.currentTarget.getAttribute('data-url');
    // Navigate to the URL
    window.location.href = url;
 
  }

  render() {
    console.log('this.props', this.props.data);
    if (this.props.data) {
      // Using an arrow function to retain the context of 'this'
      var blogs = this.props.data.blog.map((blog) => {
        var blogImage = 'images/blogs/' + blog.image;
        return (
          <div key={blog.title} className="columns blogs-item">
            <div className="item-wrap">
              <a href="#" title={blog.title} data-url={blog.url} onClick={this.handleBlogClick}>
                <img alt={blog.title} src={blogImage} />
                <div className="overlay">
                  <div className="blogs-item-meta">
                    <h5>{blog.title}</h5>
                    <p>{blog.category}</p>
                  </div>
                </div>
                <div className="link-icon"><i className="fa fa-link"></i></div>
              </a>
            </div>
          </div>
        );
      });
    }

    return (
      <section id="blogs">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>Blogs</h1>
            <div id="blogs-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
              {blogs}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Blogs;
